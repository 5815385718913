import React, { useCallback } from 'react';
import {
  Account,
  AfcSubmission,
  AfcSubmissionCandidateOpportunity,
  Candidate,
  GroupedExperiences,
  PracticeArea,
  Taxonomy,
} from '@axiom/validation';

import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Gutter } from '../../layout/Gutter/Gutter';
import { ClientEngagementNoTalentMessage } from '../ClientEngagementNoTalentMessage/ClientEngagementNoTalentMessage';
import { BioPdfCandidate } from '../BioPdf/BioPdfCandidate';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { SubmissionPdfDescriptionMultiTalent } from '../SubmissionPdfDescriptionMultiTalent/SubmissionPdfDescriptionMultiTalent';
import { PdfWantToTakeAction } from '../PdfWantToTakeAction/PdfWantToTakeAction';
import { LightTheme } from '../../theme/LightTheme/LightTheme';

import { SubmissionPdfEngagementDetails } from './SubmissionPdfEngagementDetails';
import { SubmissionPdfHeader } from './SubmissionPdfHeader';

export type SubmissionPdfTypes = {
  accountId: Account['id'];
  submission: AfcSubmission;
  candidateOpps: AfcSubmissionCandidateOpportunity[];
  allGroupedExperiences: GroupedExperiences[][];
  practiceAreas: PracticeArea[];
  newEngagementUrl: string;
  clientUrl: string;
  loginUrl: string;
  taxonomy: Taxonomy;
  newEngagementInNewTab?: boolean;
};

export const SubmissionPdf = ({
  accountId,
  submission,
  candidateOpps,
  allGroupedExperiences,
  practiceAreas,
  newEngagementUrl,
  clientUrl,
  loginUrl,
  taxonomy,
  newEngagementInNewTab,
}: SubmissionPdfTypes) => {
  const getExperiences = useCallback(
    (id: Candidate['id']): GroupedExperiences[] => {
      return (
        allGroupedExperiences.find(ge => ge?.[0]?.candidateId === id) || []
      );
    },
    [allGroupedExperiences, candidateOpps]
  );

  return (
    <div className="k-pdf-export">
      <LightTheme>
        <Layout background="panelLight" name="SUBMISSION_PDF">
          <LayoutItem>
            <Grid>
              <GridRow gutterBottom="24px">
                <GridColumn>
                  <SubmissionPdfHeader
                    submission={submission}
                    clientUrl={clientUrl}
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="32px">
                <GridColumn>
                  <SubmissionPdfDescriptionMultiTalent loginUrl={loginUrl} />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="32px">
                <GridColumn>
                  <SubmissionPdfEngagementDetails
                    submission={submission}
                    taxonomy={taxonomy}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  {candidateOpps.length > 0 ? (
                    candidateOpps.map(candidateOpp => (
                      <Gutter bottom="32px" key={candidateOpp.candidate.id}>
                        <BioPdfCandidate
                          candidate={candidateOpp.candidate}
                          submissionDescription={
                            candidateOpp.submissionDescription
                          }
                          groupedExperiences={getExperiences(
                            candidateOpp.candidate.id
                          )}
                          submission={submission}
                          practiceAreas={practiceAreas}
                          taxonomy={taxonomy}
                          removeLogo
                        />
                        {!!accountId && !!submission?.submissionId && (
                          <Gutter horizontal="32px">
                            <PdfWantToTakeAction
                              candidate={candidateOpp.candidate}
                              accountId={accountId}
                              submissionId={submission.submissionId}
                              clientUrl={clientUrl}
                            />
                          </Gutter>
                        )}
                      </Gutter>
                    ))
                  ) : (
                    <Gutter horizontal="24px" bottom="32px">
                      <ClientEngagementNoTalentMessage
                        newEngagementInNewTab={newEngagementInNewTab}
                        newEngagementUrl={newEngagementUrl}
                      />
                    </Gutter>
                  )}
                </GridColumn>
              </GridRow>
            </Grid>
          </LayoutItem>
        </Layout>
      </LightTheme>
    </div>
  );
};
