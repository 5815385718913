import React from 'react';
import { Submission, Taxonomy } from '@axiom/validation';

import { Gutter } from '../../layout/Gutter/Gutter';
import { Grid } from '../../layout/Grid/Grid';
import { GridRow } from '../../layout/Grid/GridRow';
import { GridColumn } from '../../layout/Grid/GridColumn';
import { Card } from '../../layout/Card/Card';
import { CardHeader } from '../../layout/Card/CardHeader';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { ClientEngagementsDetailsContent } from '../ClientEngagementsDetailsContent/ClientEngagementsDetailsContent';

export const SubmissionPdfEngagementDetails = ({
  submission,
  taxonomy,
}: {
  submission: Submission;
  taxonomy: Taxonomy;
}) => {
  return (
    <Gutter horizontal="24px">
      <Grid name="SUBMISSION_DETAILS">
        <GridRow>
          <GridColumn>
            <Card className="with-borders">
              <CardHeader>
                <SmallHeader>Engagement Details</SmallHeader>
              </CardHeader>
              <CardSection>
                <ClientEngagementsDetailsContent
                  submission={submission}
                  taxonomy={taxonomy}
                />
              </CardSection>
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>
    </Gutter>
  );
};
