import React from 'react';
import moment from 'moment-timezone';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  Gutter,
  CalendarTimeZoneUtil,
  Dropdown,
  Form,
  Layout,
  LayoutItem,
  ImageCircle,
  CandidateProfileUtil,
  Textarea,
} from '@axiom/ui';
import {
  SubmissionCandidate,
  CalendarManualInterview,
  TimeZone,
} from '@axiom/validation';
import { PossibleImageSizes, NO_TIMEZONE } from '@axiom/const';

import { TalentInterviewPromptModalSchema } from './talent-interview-prompt-modal-schema';

export interface PromptModalProps {
  candidate: SubmissionCandidate;
  onClose: () => void;
  onBack: () => void;
  onNext: (
    id: TimeZone['id'],
    notes: CalendarManualInterview['availabilityNote']
  ) => void;
}

export const TalentInterviewPromptModal = ({
  candidate,
  onClose,
  onBack,
  onNext,
}: PromptModalProps) => {
  const timezoneOptions = CalendarTimeZoneUtil.getDropDownOptions();
  const { timezone: talentIana } = candidate.calendar || {};
  const guessIana = moment.tz.guess();
  const timezone = CalendarTimeZoneUtil.getZoneFromMomentZone(
    moment.tz(new Date(), talentIana || guessIana)
  );
  return (
    <Form
      name="INTERVIEW_PROMPT_FORM"
      initialValues={{
        TIMEZONE_DROPDOWN: timezone === NO_TIMEZONE ? null : timezone.id,
        AVAILABILITY_NOTES: null,
      }}
      schema={TalentInterviewPromptModalSchema}
      onSubmit={formData => {
        const { TIMEZONE_DROPDOWN, AVAILABILITY_NOTES } = formData;
        onNext(TIMEZONE_DROPDOWN ?? timezone.id, AVAILABILITY_NOTES);
      }}
    >
      {({ fireSubmit, values }) => {
        return (
          <Modal name="INTERVIEW_PROMPT_MODAL">
            <ModalHeader
              onClose={() => onClose()}
              name="INTERVIEW_PROMPT_MODAL_HEADER"
            >
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid name="HEADER_TEXT">
                  Interview {candidate.calculatedFirstName}
                </LayoutItem>
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Paragraph name="SECTION_DESCRIPTION">
                Select your time zone and suggest some times to chat with{' '}
                {candidate.calculatedFirstName}.
              </Paragraph>
              <Gutter bottom="16px" />
              <Dropdown
                label="Your time zone"
                options={timezoneOptions}
                name="TIMEZONE_DROPDOWN"
                displayKey="label"
                valueKey="value"
              />
              <Gutter bottom="16px" />
              <Textarea
                label="Suggest 2 or more days and times for a 30-minute interview."
                description="Include the day, date, and time. Ex: Monday 9/16/2024 at 1pm."
                name="AVAILABILITY_NOTES"
              />
            </ModalSection>
            <ModalFooter>
              <Button
                name="BACK_BUTTON"
                onClick={() => onBack()}
                variation="outline"
              >
                Back
              </Button>
              <Button name="NEXT_BUTTON" onClick={fireSubmit}>
                Next
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  );
};
