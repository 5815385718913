import React, { useState } from 'react';
import {
  Button,
  CardSection,
  colors,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  LargeHeader,
  Layout,
  LayoutBleed,
  DarkTheme,
  Card,
  LayoutItem,
} from '@axiom/ui';
import { useParams } from 'react-router-dom';
import { AccountConst } from '@axiom/const';

import EndCardImage from '../../public/images/DiscoverTransparentBkg.png';
import { generateAHQOpportunityURL } from '../../utils/link-util';
import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';
import { EngagementsShareModal } from '../EngagementsShareModal/EngagementsShareModal';
import { EngagementsShareContextMenu } from '../EngagementsShareContextMenu/EngagementsShareContextMenu';
import { useCurrentSubmissionData } from '../../hooks/useCurrentSubmissionData';
import { EngagementHelpModal } from '../EngagementHelpModal/EngagementHelpModal';
import { AccountSubmissionsApi } from '../../api/account-submissions-api';

export const EngagementHeader = () => {
  const params = useParams();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const { account, user } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({ account });
  const submission = params?.submissionId
    ? allSubmissions.find(sub => sub.submissionId === params.submissionId)
    : allSubmissions?.[0];
  const { accountId, currentSubmission, invalidSubmissionRequest } =
    useCurrentSubmissionData({
      account,
      submissionId: submission?.submissionId,
    });
  return invalidSubmissionRequest ? null : (
    <>
      <Card>
        <CardSection
          background={{
            image: EndCardImage,
            position: 'right center',
            color: colors.controlPrimary,
          }}
        >
          <DarkTheme backgroundName="none">
            <Layout name="CLIENT_ACTIONS_BANNER">
              <LayoutBleed>
                <Grid stackable>
                  <GridRow gutterBottom="16px">
                    <GridColumn width={12}>
                      <Button
                        to="../../engagements"
                        variation="minimal"
                        pattern="secondary"
                        name="BACK_TO_ENGAGEMENTS"
                        icon="arrow-left"
                        iconPosition="left"
                        inverse
                        onClick={() => {
                          AccountSubmissionsApi.refreshAccountSubmissionDetails(
                            account.id,
                            submission.id
                          );
                        }}
                      >
                        All Engagements
                      </Button>
                      <Gutter bottom="8px" only="mobile" />
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn
                      largeScreenWidth={8}
                      tabletWidth={6}
                      mobileWidth={12}
                    >
                      <LargeHeader>{currentSubmission.jobName}</LargeHeader>
                      <Gutter bottom="16px" only="mobile" />
                    </GridColumn>
                    <GridColumn
                      largeScreenWidth={4}
                      tabletWidth={6}
                      mobileWidth={12}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <Layout
                        direction="horizontal"
                        position="right"
                        stackableOn="mobile"
                      >
                        <LayoutItem>
                          <EngagementsShareContextMenu
                            user={user}
                            account={account}
                            onClickInviteToAccount={() =>
                              setShowShareModal(!showShareModal)
                            }
                            onClickOpenInAhq={() => {
                              window.open(
                                generateAHQOpportunityURL(submission.id)
                              );
                            }}
                          />
                          <Gutter bottom="8px" only="mobile" />
                        </LayoutItem>
                        {account.accountType === AccountConst.Types.Direct && (
                          <LayoutItem>
                            <Gutter left="8px">
                              <Button
                                fluid
                                name="need-help-button"
                                pattern="secondary"
                                variation="filled"
                                onClick={() => setShowHelpModal(true)}
                              >
                                Need Help?
                              </Button>
                            </Gutter>
                          </LayoutItem>
                        )}
                      </Layout>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </LayoutBleed>
            </Layout>
          </DarkTheme>
        </CardSection>
      </Card>
      {!!showShareModal && (
        <EngagementsShareModal
          accountName={account.calculatedName}
          accountId={accountId}
          currentSubmission={currentSubmission}
          onClose={() => setShowShareModal(!showShareModal)}
        />
      )}
      {showHelpModal && (
        <EngagementHelpModal
          onClose={() => setShowHelpModal(false)}
          currentSubmission={currentSubmission}
        />
      )}
    </>
  );
};
