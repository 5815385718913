import React, { useState } from 'react';
import { CandidateIndustry, SearchCandidate, User } from '@axiom/validation';
import {
  Card,
  CardSection,
  CardFooter,
  Button,
  Well,
  ImageCircle,
  Layout,
  CandidateProfileUtil,
  ParagraphHeader,
  CondensedLarge,
  Gutter,
  CondensedHeader,
  useApi,
  PracticeAreaUtil,
  LightTheme,
} from '@axiom/ui';
import { PossibleImageSizes } from '@axiom/const';

import { PracticeAreaApi } from '../../api/practice-areas-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { LinkUtil } from '../../utils/link-util';
import { CandidateLegacyApi } from '../../api/candidate-legacy-api';
import { PricingUtil } from '../../utils/pricing-util';
import { TalentCarouselBadge } from '../TalentCarouselBadge/TalentCarouselBadge';

import { DiscoverTalentCarouselEngagements } from './DiscoverTalentCarouselEngagements';
import { DiscoverTalentCarouselExperiences } from './DiscoverTalentCarouselExperiences';
import { DiscoverTalentCarouselIndustry } from './DiscoverTalentCarouselIndustry';
import { DiscoverTalentCarouselYearsOfExperience } from './DiscoverTalentCarouselYearsOfExperience';

export const DiscoverTalentCarouselCard = ({
  candidate,
  industryValue = null,
  user,
}: {
  candidate: SearchCandidate;
  industryValue?: CandidateIndustry['industryValue'];
  user: User;
}) => {
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );
  const { industries } = taxonomy;
  const [favorited, setFavorited] = useState(false);

  const industry = candidate.industries?.find(
    ind => ind.industryValue === industryValue
  );
  const { fullTimeDailyRate, adHocHourlyRate, pricingAvailable } =
    PricingUtil.getPricingData(candidate, user);
  const { practiceArea } = PracticeAreaUtil.getPracticeAreaAndSpecialty(
    practiceAreas,
    candidate.practiceArea?.id
  );

  return (
    <Card name="DISCOVER_TALENT_CAROUSEL_CARD" stretched>
      <CardSection divider>
        <Well background="blue">
          <Layout horizontalGutter="16px" position="middle">
            <div>
              <ImageCircle
                name="DISCOVER_TALENT_IMAGE"
                size="small"
                background="light blue"
                imageName={candidate?.calculatedDisplayName}
                src={CandidateProfileUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </div>
            <Layout direction="vertical" verticalGutter="8px">
              <ParagraphHeader name="DISCOVER_TALENT_NAME">
                {candidate.calculatedDisplayName}
              </ParagraphHeader>
              <LightTheme backgroundName="none">
                <TalentCarouselBadge candidate={candidate} />
              </LightTheme>
            </Layout>
          </Layout>
          <Gutter bottom="16px" />

          <Layout verticalGutter="16px" direction="vertical">
            <Layout horizontalGutter="16px">
              <DiscoverTalentCarouselYearsOfExperience candidate={candidate} />
            </Layout>
            {industry?.industryValue && (
              <Layout horizontalGutter="16px">
                <DiscoverTalentCarouselIndustry
                  industry={industry}
                  industries={industries}
                />
              </Layout>
            )}
            <DiscoverTalentCarouselEngagements candidate={candidate} />
          </Layout>
        </Well>
        <Gutter bottom="16px" />
        {!!candidate.practiceArea?.id && (
          <Gutter bottom="16px">
            <CondensedHeader>Primary Practice Area</CondensedHeader>
            <CondensedLarge name="DISCOVER_PRACTICE_AREA">
              {PracticeAreaUtil.formatPracticeAreaSpecialty(
                practiceAreas,
                candidate.practiceArea.id
              )}
            </CondensedLarge>
          </Gutter>
        )}
        {!!pricingAvailable && (
          <Gutter bottom="16px">
            <Well>
              <CondensedHeader>Rate:</CondensedHeader>
              <CondensedLarge name="DISCOVER_RATE">
                Starting at {fullTimeDailyRate} for a five-day week
                {!!adHocHourlyRate &&
                  practiceArea.name !==
                    taxonomy.practiceAreas.LegalSupport.fullName &&
                  `; Up to ${adHocHourlyRate} for ad hoc hourly`}
              </CondensedLarge>
            </Well>
          </Gutter>
        )}
        <DiscoverTalentCarouselExperiences candidate={candidate} />
      </CardSection>
      <CardFooter>
        <Button
          onClick={async () => {
            await (favorited
              ? CandidateLegacyApi.deleteSavedCandidate(candidate.id)
              : CandidateLegacyApi.createSavedCandidate(candidate.id));
            setFavorited(!favorited);
          }}
          icon={favorited ? 'star-filled' : 'star'}
          variation="outline"
          name="SAVE_TALENT_BUTTON"
        >
          {favorited ? 'Saved' : 'Save'}
        </Button>
        <Button
          toTab={LinkUtil.getSharePath(candidate, practiceAreas)}
          name="VIEW_TALENT_BUTTON"
        >
          View Talent
        </Button>
      </CardFooter>
    </Card>
  );
};
