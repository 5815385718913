import { useParams } from 'react-router-dom';
import { CookieUtil, useApi } from '@axiom/ui';
import { AccountConst } from '@axiom/const';

import { UserApi } from '../api/user-api';
import { AccountLegacyApi } from '../api/account-legacy-api';

export const useUserAccountData = () => {
  const { accountId } = useParams();
  const hasUserCookie = !!CookieUtil.get('user') || null;
  const [{ data: user } = { data: null }] = useApi(
    hasUserCookie && UserApi.readUser()
  );

  const [{ data: account } = { data: {} }] = useApi(
    hasUserCookie &&
      AccountLegacyApi.getAccount(
        accountId || user?.contact?.accounts?.[0]?.id,
        {
          expand: [AccountConst.Expansions.accountTeamUserInfo],
        }
      )
  );

  if (
    account.industryValue?.toLowerCase() === 'na' ||
    account.industryValue?.toLowerCase() === 'n/a'
  ) {
    account.industryValue = null;
  }

  return {
    user,
    account,
  };
};
