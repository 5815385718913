import React from 'react';
import {
  Button,
  Card,
  CardSection,
  Carousel,
  Gutter,
  Layout,
  LayoutItem,
  LargeHeader,
  DarkTheme,
} from '@axiom/ui';

import { useUserAccountData } from '../../hooks/useUserAccountData';
import { useSubmissionsData } from '../../hooks/useSubmissionsData';

import { EngagementCarouselTileButton } from './EngagementCarouselTileButton';
import { EngagementCarouselEndCard } from './EngagementCarouselEndCard';

type EngagementCarouselType = {
  maxDisplayCount?: number;
};

export const EngagementCarousel = ({
  maxDisplayCount = 8,
}: EngagementCarouselType) => {
  const { account } = useUserAccountData();
  const { allSubmissions } = useSubmissionsData({
    account,
  });

  const submissions =
    allSubmissions.length > maxDisplayCount
      ? allSubmissions.slice(0, maxDisplayCount)
      : allSubmissions;

  const buildChildren = () => {
    const kids = submissions.map(submission => {
      return (
        <EngagementCarouselTileButton
          account={account}
          submission={submission}
          key={`${submission.accountId}-${submission.submissionId}`}
        />
      );
    });
    kids.push(<EngagementCarouselEndCard account={account} key="endCard" />);

    return kids;
  };

  return (
    <Card name="ENGAGEMENTS_CAROUSEL_CONTAINER">
      <CardSection background="blue">
        <Layout>
          <LayoutItem fluid>
            <LargeHeader name="HEADER">
              Recent engagements ({submissions.length})
            </LargeHeader>
          </LayoutItem>
          <LayoutItem>
            <Button to="./engagements" name="VIEW_ENGAGEMENTS">
              View all engagements
            </Button>
          </LayoutItem>
        </Layout>
        <Gutter bottom="24px" />
        <DarkTheme backgroundName="none">
          {submissions.length > 0 ? (
            <Carousel name="ENGAGEMENTS_CAROUSEL">{buildChildren()}</Carousel>
          ) : (
            <EngagementCarouselEndCard account={account} />
          )}
        </DarkTheme>
      </CardSection>
    </Card>
  );
};
