import React from 'react';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Divider,
  Card,
  CardSection,
  CardFooter,
  SmallHeader,
  CardHeader,
  Paragraph,
  ImageCircle,
  ParagraphHeader,
  TalentExperiences,
  TalentCertifications,
  TalentQualifications,
  TalentLanguages,
  CandidateBadges,
  OccupationTypeUtil,
  TalentEducation,
  useApi,
  Layout,
  PillsItem,
  CondensedMedium,
  Flashy,
  Pills,
  useBreakpoint,
  PracticeAreaUtil,
  LayoutItem,
} from '@axiom/ui';
import {
  PossibleImageSizes,
  Education,
  BarredLocationsConst,
  DisplayPronouns,
  Certification,
} from '@axiom/const';
import {
  CandidateYearsOfExperienceModel,
  CandidateIndustryModel,
} from '@axiom/ui-models';
import {
  GroupedExperiences,
  LimitedCandidate,
  PracticeArea,
  User,
} from '@axiom/validation';

import { TaxonomyApi } from '../../api/taxonomy-api';
import { FormInquireWithAxiomModalStore } from '../../stores/form-inquire-with-axiom-modal-store';
import { CandidateUtil } from '../../utils/candidate-util';
import { BarredLocationsUtil } from '../../utils/barred-locations-util';
import {
  isAFCUser,
  isClientUser,
  isLoggedInUser,
} from '../../utils/roles-util';
import { WindowUtil } from '../../utils/window-util';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';
import { TalentRateAndAvailabilityRow } from '../TalentRateAndAvailabilityRow/TalentRateAndAvailabilityRow';
import { PricingUtil } from '../../utils/pricing-util';

import { TalentDetailsRateLimitInfo } from './TalentDetailsRateLimitInfo';

const emptyPlaceholder = '--';

const { QUALIFICATION_AWARDS } = Certification;

export const TalentDetails: React.FC<{
  candidate: LimitedCandidate;
  user: User;
  practiceAreas: PracticeArea[];
  groupedExperiences: GroupedExperiences[];
}> = ({ candidate, user, practiceAreas, groupedExperiences }) => {
  const { isMobile } = useBreakpoint();
  const [{ data: taxonomy }] = useApi(TaxonomyApi.readTaxonomy());
  const isUserLoggedIn = user && isLoggedInUser(user);
  const isAuthorized = user && isAFCUser(user);
  const industries = new CandidateIndustryModel(
    candidate.industries ?? []
  ).formatIndustries();
  const lawDegreeSchools = candidate?.degrees?.filter(
    item => (Education.LAWDEGREES as Record<string, string>)[item.degree]
  );
  const yearsOfExperienceRange = new CandidateYearsOfExperienceModel(
    candidate?.yearsOfExperience
  ).getYearsOfExperienceRange();
  const formattedPracticeAreaSpecialty =
    CandidateUtil.formatPracticeAreaSpecialty(
      practiceAreas,
      candidate?.practiceArea?.id
    );
  const formattedLawFirms = CandidateUtil.getFormattedLawFirms(candidate);
  const formattedLocation = CandidateUtil.formatCandidateLocation(
    candidate,
    isAuthorized
  );
  const formattedLegalRole = OccupationTypeUtil.professionFromOccupationType(
    candidate?.occupationType,
    user
  );
  const filteredBarredLocations = candidate?.barredLocations?.filter(
    admission => admission.name !== BarredLocationsConst.Names.NotAdmitted
  );
  const numberOfHighlights = [
    industries.length > 0,
    formattedPracticeAreaSpecialty,
    yearsOfExperienceRange !== emptyPlaceholder,
    lawDegreeSchools?.length > 0,
    formattedLocation,
    filteredBarredLocations?.length > 0,
    formattedLegalRole && formattedLegalRole !== 'Lawyer',
  ].filter(Boolean).length;

  const { fullTimeDailyRate, adHocHourlyRate, pricingAvailable } =
    PricingUtil.getPricingData(candidate, user);

  const { practiceArea } = PracticeAreaUtil.getPracticeAreaAndSpecialty(
    practiceAreas,
    candidate.practiceArea?.id
  );

  const awardTypes = Object.values(QUALIFICATION_AWARDS);
  const calculateExistenceOfCertificationsAndQualifications = (
    candidate?.certifications ?? []
  ).reduce(
    (acc, cert) => {
      if (awardTypes.includes(cert.award)) {
        acc.hasQualifications = true;
      } else {
        acc.hasCertifications = true;
      }
      return acc;
    },
    { hasCertifications: false, hasQualifications: false }
  );
  const { hasCertifications, hasQualifications } =
    calculateExistenceOfCertificationsAndQualifications;

  return (
    <>
      <Card name="TALENT_DETAILS_CARD">
        <CardSection divider>
          <Layout
            horizontalGutter="16px"
            stackableOn="mobile"
            verticalGutter="16px"
          >
            <LayoutItem>
              <ImageCircle
                name="TALENT_DETAILS_IMAGE_CIRCLE"
                imageName={candidate?.calculatedDisplayName}
                src={CandidateUtil.getProfileImageUri(
                  candidate,
                  PossibleImageSizes.W_100
                )}
              />
            </LayoutItem>
            <LayoutItem fluid>
              <Layout direction="vertical">
                <LayoutItem bottomGutter="8px">
                  <Layout position="middle" horizontalGutter="8px">
                    <LayoutItem>
                      <SmallHeader name="TALENT_DETAILS_CALCULATED_DISPLAY_NAME">
                        {candidate.calculatedDisplayName}
                      </SmallHeader>
                    </LayoutItem>
                    <LayoutItem fluid>
                      {candidate.pronouns && (
                        <Paragraph name="TALENT_DETAILS_PRONOUNS">
                          {DisplayPronouns[candidate.pronouns]}
                        </Paragraph>
                      )}
                    </LayoutItem>
                  </Layout>
                </LayoutItem>
                <LayoutItem fluid>
                  <Paragraph name="TALENT_DETAILS_PRACTICE_AREA_SPECIALTY">
                    {formattedPracticeAreaSpecialty}
                  </Paragraph>
                  <Paragraph name="TALENT_DETAILS_LOCATION">
                    {formattedLocation}
                  </Paragraph>
                </LayoutItem>
                {candidate.badges?.length > 0 && (
                  <LayoutItem topGutter="8px">
                    <Gutter bottom="8px" />
                    <CandidateBadges badges={candidate.badges} />
                  </LayoutItem>
                )}
              </Layout>
            </LayoutItem>
            {!!pricingAvailable && (
              <LayoutItem>
                <Layout
                  direction="vertical"
                  position="right"
                  verticalGutter="8px"
                >
                  {isMobile ? (
                    <Flashy name="TALENT_DETAILS_LOW_RATE_FLASHY" bold>
                      Starting at {fullTimeDailyRate} for a five-day week
                    </Flashy>
                  ) : (
                    <Pills>
                      <PillsItem
                        pattern="secondary"
                        name="TALENT_DETAILS_LOW_RATE_PILL"
                      >
                        Starting at {fullTimeDailyRate} for a five-day week
                      </PillsItem>
                    </Pills>
                  )}
                  {!!adHocHourlyRate &&
                    practiceArea.name !==
                      taxonomy.practiceAreas.LegalSupport.fullName && (
                      <CondensedMedium
                        name="TALENT_DETAILS_HIGH_RATE"
                        className={isMobile ? null : 'text-align-right'}
                      >
                        <Flashy color="textSubtle">
                          up to {adHocHourlyRate} for ad hoc hourly
                        </Flashy>
                      </CondensedMedium>
                    )}
                </Layout>
              </LayoutItem>
            )}
          </Layout>
          <Gutter bottom="16px" />
          <TalentRateAndAvailabilityRow candidate={candidate} user={user} />
        </CardSection>
        <CardFooter stackableOn="mobile">
          <FavoriteButton user={user} candidate={candidate} />
          <Button
            name="INQUIRE"
            onClick={() => {
              FormInquireWithAxiomModalStore.load(
                candidate,
                isClientUser(user) ? user : {}
              );
            }}
          >
            Request Info
          </Button>
        </CardFooter>
      </Card>
      <Gutter bottom="16px" />
      {!!candidate?.publicCandidateSummary && (
        <Gutter bottom="16px">
          <Card>
            <CardHeader>
              <SmallHeader>Summary</SmallHeader>
            </CardHeader>
            <CardSection>
              <Paragraph name="TALENT_DETAILS_SUMMARY">
                {candidate?.publicCandidateSummary}
              </Paragraph>
            </CardSection>
          </Card>
        </Gutter>
      )}
      {numberOfHighlights > 0 && (
        <Card name="TALENT_DETAILS_HIGHLIGHTS">
          <CardHeader>
            <SmallHeader>Highlights</SmallHeader>
          </CardHeader>
          <CardSection>
            <Grid stackable>
              {!!formattedPracticeAreaSpecialty && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Practice Area</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_PRACTICE_AREA">
                      {formattedPracticeAreaSpecialty}
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {industries.length > 0 && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Industries</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_INDUSTRIES">
                      <Divider>
                        {industries.map(
                          ({ industryValue, yearsOfExperience }) => (
                            <span key={industryValue}>
                              <span>
                                {
                                  taxonomy.industries[industryValue]
                                    .displayValue
                                }
                              </span>
                              &nbsp;
                              <span>
                                (
                                {CandidateUtil.formatYOE(
                                  `${yearsOfExperience}`
                                )}
                                )
                              </span>
                            </span>
                          )
                        )}
                      </Divider>
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {yearsOfExperienceRange !== emptyPlaceholder && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Years of Experience</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_YEARS_OF_EXPERIENCE">
                      <span>{yearsOfExperienceRange}</span>
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {!!pricingAvailable && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Rate</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_RATE">
                      Starting at {fullTimeDailyRate} for a five-day week
                      {!!adHocHourlyRate &&
                        `; Up to ${adHocHourlyRate} for ad hoc hourly`}
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {formattedLawFirms?.length > 0 && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Law Firms</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_LAW_FIRMS">
                      <Divider>
                        {formattedLawFirms?.map(({ client }) => (
                          <span key={client}>{client}</span>
                        ))}
                      </Divider>
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {lawDegreeSchools?.length > 0 && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Law Schools</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_LAW_DEGREE_SCHOOLS">
                      <Divider>
                        {lawDegreeSchools?.map(({ institution }) => (
                          <span key={institution}>{institution}</span>
                        ))}
                      </Divider>
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {!!formattedLocation && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Location</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_LOCATION">
                      {formattedLocation}
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {filteredBarredLocations?.length > 0 && (
                <GridRow gutterBottom="16px">
                  <GridColumn width={3}>
                    <ParagraphHeader>Admissions</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_BARRED_LOCATIONS">
                      <Divider>
                        {candidate?.barredLocations?.map(({ name }) => {
                          return (
                            <span key={name}>
                              {BarredLocationsUtil.replaceCanadian(name)}
                            </span>
                          );
                        })}
                      </Divider>
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
              {!!formattedLegalRole && formattedLegalRole !== 'Lawyer' && (
                <GridRow>
                  <GridColumn width={3}>
                    <ParagraphHeader>Legal Role</ParagraphHeader>
                  </GridColumn>
                  <GridColumn width={9}>
                    <Paragraph name="TALENT_DETAILS_HIGHLIGHTS_LEGAL_ROLE">
                      {formattedLegalRole}
                    </Paragraph>
                  </GridColumn>
                </GridRow>
              )}
            </Grid>
          </CardSection>
        </Card>
      )}
      <Gutter bottom="16px" />
      {!isUserLoggedIn && (
        <Gutter bottom="16px">
          <Card name="TALENT_DETAILS_SIGN_IN">
            <CardHeader>
              <SmallHeader>Experiences</SmallHeader>
            </CardHeader>
            <CardSection divider>
              <Paragraph>
                Sign in to see {candidate.calculatedDisplayName}'s experience.
              </Paragraph>
            </CardSection>
            <CardFooter>
              <Button
                name="SIGN_IN"
                className="gtm-talent-details-sign-in"
                onClick={() => WindowUtil.toLogin()}
              >
                Sign In
              </Button>
            </CardFooter>
          </Card>
        </Gutter>
      )}
      {!!isAuthorized && !!candidate.restricted && (
        <Gutter bottom="16px">
          <TalentDetailsRateLimitInfo />
        </Gutter>
      )}
      {groupedExperiences.length > 0 && (
        <Gutter bottom="16px">
          <Card name="TALENT_DETAILS_EXPERIENCES">
            <CardHeader>
              <SmallHeader>Experience</SmallHeader>
            </CardHeader>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </Card>
        </Gutter>
      )}
      {candidate?.degrees?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="TALENT_DETAILS_EDUCATION">
            <CardHeader>
              <SmallHeader>Education</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentEducation candidate={candidate} />
            </CardSection>
          </Card>
        </Gutter>
      )}
      {candidate?.certifications?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="TALENT_DETAILS_ACCOMPLISHMENTS">
            <CardHeader>
              <SmallHeader>Accomplishments</SmallHeader>
            </CardHeader>
            {hasCertifications && (
              <CardSection
                divider
                name="TALENT_DETAILS_CERTIFICATIONS_SUBSECTION"
              >
                <TalentCertifications candidate={candidate} />
              </CardSection>
            )}
            {hasQualifications && (
              <CardSection name="TALENT_DETAILS_QUALIFICATIONS_SUBSECTION">
                <TalentQualifications candidate={candidate} />
              </CardSection>
            )}
          </Card>
        </Gutter>
      )}
      {candidate?.languages?.length > 0 && (
        <Gutter bottom="16px">
          <Card name="TALENT_DETAILS_LANGUAGES">
            <CardHeader>
              <SmallHeader>Languages</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentLanguages candidate={candidate} />
            </CardSection>
          </Card>
        </Gutter>
      )}
    </>
  );
};
