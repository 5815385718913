import React, { useEffect, useRef } from 'react';
import { useApi, PDFExport, SubmissionPdf, StringUtil } from '@axiom/ui';
import { useParams } from 'react-router-dom';

import { DateUtil } from '../../utils/date-util';
import { PracticeAreaApi } from '../../api/practice-areas-api';
import { CandidateApi } from '../../api/candidate-api';
import { TaxonomyApi } from '../../api/taxonomy-api';
import { EnvUtil } from '../../utils/env-util';
import { useEngagementTalentData } from '../../hooks/useEngagementTalentData';

import { PDFWrapper } from './SubmissionPdfDownloadStyles';

export const SubmissionPdfDownload = ({
  onSave = null,
}: {
  onSave?: () => void;
}) => {
  const { accountId, submissionId } = useParams();
  const pdfRoot = useRef(null);
  const { allCandidateOpps, submission } = useEngagementTalentData();
  const [{ data: practiceAreas }, { data: taxonomy }] = useApi(
    PracticeAreaApi.readPracticeAreas(),
    TaxonomyApi.readTaxonomy()
  );
  const allGroupedExperiences = useApi(
    ...allCandidateOpps.map(({ candidate: { id } }) =>
      CandidateApi.readCandidateGroupedExperiences(id, {
        submissionId,
      })
    )
  ).map(response => response.data);

  const cleanCompanyName = StringUtil.cleanStringForFileName(
    submission.jobName
  );
  const fileName = `Axiom Matches_${cleanCompanyName}_${DateUtil.formatAsDate(
    new Date()
  )}.pdf`;

  useEffect(() => {
    const listenerFunction = window.close;
    if (pdfRoot?.current?.save) {
      if (onSave instanceof Function) {
        setTimeout(() => pdfRoot.current.save(onSave), 500);
      } else {
        window.addEventListener('focus', listenerFunction);

        setTimeout(() => pdfRoot.current.save(), 500);
      }
    }

    return () => {
      window.removeEventListener('focus', listenerFunction);
    };
  }, [pdfRoot]);

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        fileName={fileName}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
      >
        <SubmissionPdf
          accountId={accountId}
          submission={submission}
          candidateOpps={allCandidateOpps}
          allGroupedExperiences={allGroupedExperiences}
          practiceAreas={practiceAreas}
          newEngagementUrl={EnvUtil.hubspotTalentFinder}
          clientUrl={EnvUtil.clientEngagementsUrl}
          loginUrl={EnvUtil.loginUrl}
          taxonomy={taxonomy}
        />
      </PDFExport>
    </PDFWrapper>
  );
};
