import { CalendarComponentEvent } from '@axiom/validation';

import { calendarPermissionsType } from '../../../utils/calendar-permissions-util';

import { EventStates } from './calendar-events-const';

export const useCalendarAccessors = (permissions: calendarPermissionsType) => {
  const handleDraggableAccessor = (event: CalendarComponentEvent) => {
    return (
      (event.busy &&
        event.state !== EventStates.SAVED &&
        permissions.staticEvent.canMove) ||
      (!event.busy && permissions.eventBoundary.canMove)
    );
  };

  const handleResizableAccessor = (event: CalendarComponentEvent) => {
    return (
      (event.busy &&
        event.state !== EventStates.SAVED &&
        permissions.staticEvent.canResize) ||
      !event.busy
    );
  };

  return {
    handleDraggableAccessor,
    handleResizableAccessor,
  };
};
